import Head from 'next/head';
import axios from 'axios';
import BrowseVehiclesContent from '@/flow/BrowseVehicles';
import API_ENDPOINTS from '@/helpers/apiEndpoint';
import checkIfProtectedRoute from '@/helpers/checkIfProtectedRoute';
import config from '@/helpers/config';
import ROUTE_NAME from '@/helpers/url-constants';
import styles from '@/styles/home.module.scss';

const BrowseVehicles = ({ vehicles, count }) => {
  return (
    <>
      <Head>
        <title>Browse Vehicles for Car Subscriptions - Drive Fuze</title>
        <meta
          name="description"
          content="Choose from our wide range of cars to suit your needs and budget with a Drive Fuze car subscription today"
        />
        <meta
          property="og:description"
          content="Choose from our wide range of cars to suit your needs and budget with a Drive Fuze car subscription today"
        />
        <meta property="og:image" content={ROUTE_NAME.SHARE_IMAGE} />
      </Head>
      <div className={styles.container}>
        <BrowseVehiclesContent vehicles={vehicles} count={count} />
      </div>
    </>
  );
};

export default BrowseVehicles;

export async function getServerSideProps(ctx) {
  if (checkIfProtectedRoute(ctx)) {
    return { props: {} };
  }

  const params = {
    fkPartnerId: config.API.partner_id,
    page: 1,
    limit: config.BROWSE_VEHICLE_DEFAULT_LIMIT,
  };

  const { data } = await axios.post(
    `${config.API.url + API_ENDPOINTS.LISTING_BROWSE_VEHICLE}`,
    params,
  );

  return {
    props: {
      vehicles: data.status && data?.data?.list?.length ? data?.data?.list : [],
      count: data.status && data?.data?.count ? data?.data?.count : 0,
    },
  };
}
